import { Box } from "@mui/material";
import React, { useState } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import SectionAds from "../SectionAds/SectionAds.tsx";

import SectionTec from "../SectionTech/SectionTec.tsx";

const WatchMovie = () => {
  const { movie, name } = useParams();
  const [isActive, setIsActive] = useState(false);
  const movies = Number(movie);

  const handleClick = () => {
    setIsActive(true);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h2>Seu player já vai começar</h2>
      <Box
        sx={{
          marginBottom: 10,
          background: "white",
          maxWidth: "900px",
          marginInline: "6px",
          borderRadius: "8px",
        }}
      >
        <SectionTec />
      </Box>
      <Box
        sx={{
          width: {
            md: 600,
            xs: "90%",
          },
          height: {
            xs: 400,
          },
          position: "relative",
        }}
      >
        <Link
          to={"https://techfatos.com/"}
          target="_blank"
          style={{
            display: `${isActive ? "none" : "flex"}`,
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
          onClick={handleClick}
        />
        <iframe
          src={`${process.env.REACT_APP_URL_MOVIE}${movies}#noLink`}
          width={"100%"}
          height={"100%"}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={`Video player for movie ${name}`}
        />
      </Box>

      <Box
        sx={{
          marginBlock: "2rem",
        }}
      >
        <SectionAds />
      </Box>
    </Box>
  );
};

export default WatchMovie;
